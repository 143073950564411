import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-video-placeholder',
  templateUrl: './video-placeholder.component.html',
  styleUrls: ['./video-placeholder.component.scss'],
})
export class VideoPlaceholderComponent implements OnInit {
  @Input() src: string = '';
  @Input() posterSrc: string = '';
  public safeUrl: SafeUrl = '';
  public posterSafeUrl: SafeUrl = '';

  constructor(public _DomSanitizer: DomSanitizer, private platform: Platform, private ref: ChangeDetectorRef) {}

  ngOnInit() {}

  public convertUrl() {
    this.posterSafeUrl = this._DomSanitizer.bypassSecurityTrustUrl(this.posterSrc);
    this.safeUrl = this._DomSanitizer.bypassSecurityTrustUrl(this.src);
    this.ref.detectChanges();
  }
}
